import { Button, TextField, TextGroup } from '@nike/eds'
import { Chips } from 'components/chips'
import { useState, useEffect } from 'react'

interface InstanceCategoriesTabProps {
  categories: string[]
  updateCategories: (categories: string[]) => void
  readOnly: boolean
}

export const InstanceCategoriesTab = ({ categories, updateCategories, readOnly }: InstanceCategoriesTabProps) => {
  const [currentCategories, setCurrentCategories] = useState<string[]>([])
  const [newCategory, setNewCategory] = useState<string>('')
  const [errorNewCategory, setErrorNewCategory] = useState<string>('')

  useEffect(() => {
    setCurrentCategories(categories)
  }, [categories])

  const addChip = () => {
    if (newCategory === '') {
      setErrorNewCategory('Category cannot be empty')
      return
    }
    if (currentCategories.includes(newCategory)) {
      setErrorNewCategory('Category already exists')
      return
    }
    updateCategories([...currentCategories, newCategory])
    setNewCategory('')
  }

  const deleteChip = (category: string) => {
    updateCategories(currentCategories.filter(cat => cat !== category))
  }

  return (
    <>
      <TextGroup>
        <Chips
          values={currentCategories}
          deleteChip={readOnly ? undefined : deleteChip}
        />
      </TextGroup>

      {!readOnly && (
        <div className='flex auto place-items-center gap-4'>
          <TextField
            id='newCategory'
            label='New category'
            value={newCategory}
            onChange={(e) => {
              setErrorNewCategory('')
              setNewCategory(e.target.value)
            }}
            hasErrors={errorNewCategory !== ''}
            errorMessage={errorNewCategory}
          />
          <Button className='self-end'
            onClick={addChip}
          >
            Add
          </Button>
        </div>
      )}
    </>
  )
}
